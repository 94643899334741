<template>
  <a-row class="content-fix org">
    <a-row class="content-title" type="flex">
      <a-button
        style="margin-right: 15px"
        type="primary"
        class="org back-btn"
        @click="$router.push({ name: 'virtual-reception' })"
      >
        <i class="t-transition icons icon-chevron-left" />
        Назад
      </a-button>
      <!-- <a-button
        style="margin-left: auto"
        type="primary"
        @click="$router.push({ name: 'appeal-create' })"
      >
        <a-icon type="plus" />{{ $t("Add") }}
      </a-button> -->
    </a-row>
    <a-row type="flex" class="table__filtrs">
      <h2>{{ title[$i18n.locale] }}</h2>
      <!-- <a-button
        :disabled="cardsIsEmpty"
        type="primary"
        style="margin: 0 0 15px auto"
        @click="removeItem(selectedPosts)"
      >
        <a-icon class="action-btns" type="delete" />
        Удалить
      </a-button> -->
    </a-row>
    <a-table
      :columns="columns"
      :data-source="list"
      :loading="loading"
      :pagination="{ defaultPageSize: 10 }"
      :row-key="(record) => record.id"
      class="faqs"
    >
      <!-- <template slot="id" slot-scope="item">
        <a-checkbox
          :checked="selectedPosts.includes(+item.id)"
          :value="+item.id"
          @change="toggleItemId(item.id)"
        />
      </template> -->

      <template slot="full_name" slot-scope="item">
        <span
          class="text-center td-post-item"
          style="cursor: pointer; display: block; width: 100%; text-align: left"
          @click="
            $router.push({ name: 'appeal-detail', params: { id: item.id } })
          "
          >{{ item.applicant_person || "Не заполнено" }}</span
        >
      </template>
      <template slot="created_at" slot-scope="item">
        <span style="display: block; width: 100%; text-align: left">
          <!-- {{ item.created_at || "Не заполнено" }} -->
          {{ moment(item.created_at).format("LLL") || "Не заполнено" }}
        </span>
      </template>
      <!-- <template slot="updated_at" slot-scope="item">
        {{ moment(item.updated_at).format("LLL") }}
      </template> -->
      <template slot="email" slot-scope="item">
        <span style="display: block; width: 100%; text-align: left">{{
          item.email || "Не заполнено"
        }}</span>
      </template>
      <template slot="address" slot-scope="item">
        <span style="display: block; width: 100%; text-align: left">{{
          item.address || "Не заполнено"
        }}</span>
      </template>
      <template slot="appeal_type" slot-scope="item">
        <span style="display: block; width: 100%; text-align: left">{{
          item.appeal_type || "Не заполнено"
        }}</span>
      </template>
      <template slot="person_type" slot-scope="item">
        <span style="display: block; width: 100%; text-align: left">{{
          item.type_person || "Не заполнено"
        }}</span>
      </template>
      <!-- <template slot="status" slot-scope="item">
        <a-tag :color="item.is_active ? 'geekblue' : 'volcano'">
          {{ item.is_active ? $t("Published") : $t("NotPublished") }}
        </a-tag>
      </template> -->

      <template slot="operation" slot-scope="item">
        <a-icon
          class="action-btns"
          type="edit"
          @click="$router.push({ name: 'mhh-detail', params: { id: item.id } })"
        />
        <a-popconfirm
          cancel-text="Нет"
          ok-text="Да"
          title="Вы действительно хотите удалить?"
          @confirm="removeItem(item)"
        >
          <a-icon class="action-btns" style="margin: 0 10px" type="delete" />
        </a-popconfirm>
      </template>
    </a-table>
  </a-row>
</template>

<script>
// const columns = [
//   {
//     title: "F.I.O",
//     key: "full_name",
//     scopedSlots: { customRender: "full_name" },
//     width: "0%"
//   },
//   {
//     title: "Murojat yo'llangan sana",
//     key: "created_at",
//     scopedSlots: { customRender: "created_at" },
//     width: "20%"
//   },
//   {
//     title: "Person type",
//     key: "person-type",
//     scopedSlots: { customRender: "person_type" },
//     width: "15%"
//   },
//   {
//     title: "Email",
//     key: "email",
//     scopedSlots: { customRender: "email" },
//     width: "20%"
//   },
//   {
//     title: "Address",
//     key: "address",
//     scopedSlots: { customRender: "address" },
//     width: "20%"
//   },
//   // {
//   //   title: "Appeal type",
//   //   key: "appeal-type",
//   //   scopedSlots: { customRender: "appeal_type" },
//   //   width: "15%"
//   // },

//   // {
//   //   title: this.$t("Status"),
//   //   key: "status",
//   //   scopedSlots: { customRender: "status" }
//   // },
//   {
//     title: this.$t("TableAction"),
//     key: "operation",
//     scopedSlots: { customRender: "operation" }
//   }
// ]
export default {
  data: () => ({
    title: {
      oz: "Murojaatlar",
      uz: "Мурожаатлар",
      ru: "Справочные темы",
      en: "Help topics"
    },
    loading: false,
    list: [],
    selectedRowKeys: [],
    selectedPosts: [],
    cardsIsEmpty: true,
    columns: [
      {
        title: "F.I.O",
        key: "full_name",
        scopedSlots: { customRender: "full_name" },
        width: "20%"
      },
      {
        title: "Murojat yo'llangan sana",
        key: "created_at",
        scopedSlots: { customRender: "created_at" },
        width: "20%"
      },
      {
        title: "Shaxs turi",
        key: "person-type",
        scopedSlots: { customRender: "person_type" },
        width: "15%"
      },
      {
        title: "Email",
        key: "email",
        scopedSlots: { customRender: "email" },
        width: "20%"
      },
      {
        title: "Manzil",
        key: "address",
        scopedSlots: { customRender: "address" },
        width: "20%"
      },
      {
        title: "Xarakat",
        key: "operation",
        scopedSlots: { customRender: "operation" }
      }
    ]
  }),
  watch: {
    $route: {
      handler: "fetchData",
      immediate: true
    }
  },
  methods: {
    toggleItemId(itemId) {
      const f = this.selectedPosts.findIndex((item) => +item === +itemId)
      if (f !== -1) {
        this.selectedPosts.splice(f, 1)
        this.cardsIsEmpty = false
      } else {
        this.selectedPosts.push(itemId)
        this.cardsIsEmpty = false
      }

      if (this.selectedPosts.length === 0) {
        this.cardsIsEmpty = true
      }
    },
    async fetchData() {
      this.loading = true
      try {
        let res = await this.$api.get("/admin/corruption-appeal/list/")
        console.log(res)
        this.list = res && res.data
      } catch (error) {
        console.error(error)
        this.loading = false
      }
      this.loading = false
    },
    async removeItem(id) {
      if (Array.isArray(id)) {
        for (let i of id) {
          try {
            const res = await this.$api.delete(
              `/admin/leadership-online-registration/notification/${i}/delete/`
            )
            if (res) {
              this.$message.success("Успешно удалено")
            }
          } catch (e) {
            this.$message.success("Ошибка при удалении")
            this.$sentry.captureMessage(e)
          }
        }
      } else {
        try {
          const res = await this.$api.delete(
            `/admin/leadership-online-registration/notification/${id}/delete/`
          )
          if (res) {
            this.$message.success("Успешно удалено")
          }
        } catch (e) {
          this.$message.success("Ошибка при удалении")
          this.$sentry.captureMessage(e)
        }
      }
      await this.fetchData()
      this.selectedCards = []
      this.cardsIsEmpty = true
    }
  }
}
</script>
